<template>
    <div class="grid">
        <div class="col-12">

            <div class="font-medium text-3xl text-900 mb-3">
                <span class="text-primary text-5xl">/</span> Perfil
            </div>
            <div class="text-500 mb-5">
                Preencha os campos abaixo
            </div>

            <Toast />

            <AppDeleteDialog v-model:visible="showAppDeleteDialog" title="Foto do usuário"
                @onConfirm="onClickDeleteRecord" @onClose="onClickCloseDeleteRecord">
            </AppDeleteDialog>

            <div class="card p-fluid w-full">

                <h5 class="mb-4">Meus dados</h5>

                <form @submit.prevent="saveProfile">

                    <div class="grid">
                        <div class="col-12 md:col-6">
                            <div class="field">
                                <label for="name">Nome</label>
                                <InputText id="name" v-model.trim="profile.name" required="true" autofocus
                                    autocomplete="off" />
                            </div>
                        </div>

                        <div class="col-12 md:col-6">
                            <div class="flex">
                                <div class="field mr-3">
                                    <label for="foto">Foto de Perfil</label>
                                    <div v-if="!profile.urlFotoPerfil" class="user-photo-template">
                                        <Image :src="'layout/images/avatar.png'"
                                            class="user-photo" height="80" preview />
                                    </div>
                                    <div v-if="profile.urlFotoPerfil" class="user-photo-template">
                                        <Image :src="profile.urlFotoPerfil || 'layout/images/avatar.png'"
                                            class="user-photo" height="80" preview />
                                    </div>
                                </div>
                                <div class="field mt-3">

                                    <span v-if="profile.urlFotoPerfil" class="p-button p-component p-button-link"
                                        @click="onClickRemoverFoto"><span
                                            class="p-button-icon p-button-icon-left pi pi-trash"></span> Remover</span>

                                    <FileUpload class="p-button-link" id="foto" mode="basic" accept="image/*"
                                        :maxFileSize="1000000" auto customUpload @uploader="uploadFoto"
                                        chooseLabel="Upload" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-12 md:col-6">
                            <div class="field">
                                <label for="email">E-mail</label>
                                <InputText id="email" v-model.trim="profile.email" disabled required="true" />
                            </div>
                        </div>
                        <div class="col-12 md:col-6">
                            <div class="field">
                                <label>Telefone</label>
                                <InputMask id="telefone" v-model="profile.phone" type="text" mask="(99) 99999-9999" />
                            </div>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <Button label="Salvar perfil" icon="pi pi-check" class="p-button-primary w-6"
                            @click="saveProfile" />
                    </div>

                </form>

            </div>

            <div v-if="atualizaSenha" class="card p-fluid w-full">

                <h5 class="mb-4">Senha</h5>

                <form @submit.prevent="updatePassword">

                    <div class="field w-6">
                        <label for="password">Senha atual</label>
                        <InputText id="password" type="password" v-model.trim="passwords.password" autocomplete="off" />
                    </div>

                    <div class="grid">
                        <div class="field col-6">
                            <label for="newpassword">Nova Senha</label>
                            <InputText id="newpassword" type="password" v-model.trim="passwords.newPassword"
                                autocomplete="off" />
                        </div>

                        <div class="field col-6">
                            <label for="repeatpassword">Repita a senha</label>
                            <InputText id="repeatpassword" type="password" v-model.trim="passwords.repeatPassword"
                                autocomplete="off" />
                        </div>
                    </div>

                    <div class="flex mt-5">
                        <Button label="Atualizar senha" icon="pi pi-check" class="p-button-primary w-6" />
                    </div>

                </form>

            </div>
        </div>
    </div>

</template>

<script>
import { getProfile, updatePassword } from '@/services/auth'
import EventBus from '../event-bus';
import BaseService from '../services/BaseService';
import { getClientBase } from '../services/http';

export default {
    data() {
        return {
            profile: {},
            passwords: {},
            atualizaSenha: false,
            showAppDeleteDialog: false,
            origemAnexo: 'users',
            tipoAnexo: 'FOTO'
        }
    },
    async mounted() {
        this.profile = await getProfile()
        this.$serviceUser = new BaseService('/users/simple-update');
        this.$serviceAnexo = getClientBase()
    },
    methods: {
        async saveProfile() {
            delete this.profile.password;
            try {
                await this.$serviceUser.save(this.profile);
                this.$toast.add({ severity: 'success', summary: 'Perfil atualizado com sucesso!', life: 3000 });
            } catch ({ response }) {
                const { data } = response
                this.$toast.add({ severity: 'error', summary: 'Erro ao atualizar perfil!', detail: data.message, life: 3000 });
            }
        },
        async updatePassword() {
            try {
                await updatePassword(this.passwords)
                this.$toast.add({ severity: 'success', summary: 'Senha atualizada com sucesso!', life: 3000 });
            } catch ({ response }) {
                const { data } = response
                this.$noty.error(data.message)
            }
        },
        async uploadFoto(event) {
            const formData = new FormData();
            formData.append('file', event.files[0]);
            formData.append('origem', this.origemAnexo);
            formData.append('origemId', this.profile.id.toString());
            formData.append('tipo', this.tipoAnexo);
            try {
                const anexo = await this.$serviceAnexo.post('/anexo', formData);
                this.profile.urlFotoPerfil = anexo.data.url;
                this.$toast.add({ severity: 'success', summary: 'Foto carregada com sucesso', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar a foto', life: 3000 });
            }
        },
        onClickRemoverFoto() {
            this.showAppDeleteDialog = true
        },
        async removerFoto() {
            try {
                const { data } = await this.$serviceAnexo.get(`/anexo/${this.origemAnexo}/` + this.profile.id)
                const anexosRemover = data.filter(p => p.tipo == this.tipoAnexo)
                for await (const anexo of anexosRemover) {
                    await this.$serviceAnexo.delete(`/anexo/${anexo.id}`)
                }
                this.profile.urlFotoPerfil = null;
                this.$toast.add({ severity: 'success', summary: 'Foto removida com sucesso', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao remover a foto', life: 3000 });
            }
        },
        onClickCloseDeleteRecord() {
            this.showAppDeleteDialog = false
        },
        onClickDeleteRecord() {
            this.removerFoto()
            this.showAppDeleteDialog = false
        }
    },
    watch: {
        profile() {
            EventBus.emit('updateProfile', this.profile)
        }
    }
}
</script>

<style scoped lang="scss">
.user-photo-template {
    border: 1px solid #ced4da;
    border-style: solid;
    width: 80px;
    height: 80px;
    align-self: center;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.user-photo {
    width: initial;
    height: inherit;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>
